/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable no-unused-vars */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

function FieldClose({ color, className }) {
    return (
        <svg
            width="9"
            height="8"
            viewBox="0 0 9 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path d="M8 7.5L1 0.499999" stroke={color} strokeLinecap="round" />
            <path d="M8 0.5L1 7.5" stroke={color} strokeLinecap="round" />
        </svg>
    );
}

FieldClose.propTypes = propTypes;
FieldClose.defaultProps = defaultProps;

export default FieldClose;
