import { useUser } from '@folklore/auth';
import { useUrlGenerator } from '@folklore/routes';
import { useTracking } from '@folklore/tracking';
import { animated } from '@react-spring/web';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';

import useMenuButtonsSpring from '../../animations/useMenuButtonsSpring';
import AccountButton from '../buttons/AccountButton';
import CloseButton from '../buttons/CloseButton';
import PillButton from '../buttons/PillButton';

import styles from '../../styles/layouts/main-menu-layout.module.css';

const propTypes = {
    brand: AppPropTypes.brand,
    withButtons: PropTypes.bool,
    closeMenu: PropTypes.func,
    children: PropTypes.node,
    className: PropTypes.string,
};

const defaultProps = {
    brand: null,
    withButtons: false,
    closeMenu: null,
    children: null,
    className: null,
};

function MainMenuLayout({ brand, children, closeMenu, withButtons, className }) {
    const { handle: brandHandle = null, slug: brandSlug = null } = brand || {};
    const url = useUrlGenerator();
    const tracking = useTracking();
    const user = useUser();
    const { subscriptions = [] } = user || {};
    const isSubscribed = subscriptions.reduce(
        (acc, { subscribed = false }) => acc || subscribed,
        false,
    );

    const { style: buttonsStyle } = useMenuButtonsSpring({
        visible: withButtons,
    });

    const onClickClose = useCallback(() => {
        if (closeMenu !== null) {
            closeMenu();
        }
        tracking.trackEvent('Navigation', 'click_menu', 'close');
    }, [closeMenu, tracking]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            data-brand={brandHandle}
        >
            {children}
            <animated.div className={styles.buttons} style={buttonsStyle}>
                {!isSubscribed ? (
                    <PillButton
                        compact
                        href={url('subscribe', {
                            brand: brandSlug,
                        })}
                        className={styles.subscribeButton}
                    >
                        <FormattedMessage defaultMessage="S’abonner" description="Button label" />
                    </PillButton>
                ) : null}
                <AccountButton
                    href={user === null ? url('login') : url('account')}
                    circleColor="#222"
                    className={styles.accountButton}
                />

                <CloseButton className={styles.closeButton} onClick={onClickClose} />
            </animated.div>
        </div>
    );
}

MainMenuLayout.propTypes = propTypes;
MainMenuLayout.defaultProps = defaultProps;

export default MainMenuLayout;
