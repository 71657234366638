/* eslint-disable react/jsx-indent */

/* eslint-disable react/jsx-props-no-spreading */
import { useIntersectionObserver, useResizeObserver } from '@folklore/hooks';
import { useVirtualizer } from '@tanstack/react-virtual';
import classNames from 'classnames';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import useCategories from '../../hooks/useCategories';
import useCollectionsInfinite from '../../hooks/useCollectionsInfinite';
import usePlaceholderItems from '../../hooks/usePlaceholderItems';

import CollectionHorizontalCard from '../cards/CollectionHorizontalCard';
import SearchFilters from '../partials/SearchFilters';
import MenuSectionTitle from '../typography/MenuSection';
import ListPanel from './ListPanel';

import styles from '../../styles/panels/collections-panel.module.css';

const propTypes = {
    className: PropTypes.string,
    closePanel: PropTypes.func.isRequired,
};

const defaultProps = {
    className: null,
};

function CollectionsPanel({ className, closePanel, ...props }) {
    const [search, setSearch] = useState(null);
    const [direction, setDirection] = useState(null);
    const [currentFilter, setCurrentFilter] = useState(null);

    const {
        pages = null,
        isFetching,
        isFetchingNextPage,
        fetchNextPage,
    } = useCollectionsInfinite(
        search !== null
            ? {
                  search,
              }
            : {
                  type:
                      currentFilter !== null && currentFilter.id !== 'all'
                          ? currentFilter.id
                          : null,
                  order: 'title',
              },
    );

    const onSearchChange = useCallback(
        (newSearch) => {
            setSearch(newSearch);
            if (newSearch !== null) {
                setCurrentFilter(null);
            }
            setDirection('backward');
        },
        [setSearch],
    );

    const onClickBack = useCallback(() => {
        closePanel();
    }, [closePanel]);

    const {
        ref: refEnd,
        entry: { isIntersecting: endInView = false },
    } = useIntersectionObserver({
        disabled: isFetching || isFetchingNextPage,
    });

    useEffect(() => {
        if (endInView && !isFetching && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [isFetching, isFetchingNextPage, endInView]);

    // const withLetters = search === null;

    const placeholderItems = usePlaceholderItems(20);
    const items = useMemo(() => {
        if (pages === null) {
            return placeholderItems;
        }
        return pages.reduce((allItems, { data: pageItems }) => [...allItems, ...pageItems], []);
    }, [pages, placeholderItems]);

    // The scrollable element for your list
    const scrollRef = useRef();
    const {
        ref: headerRef,
        entry: { contentRect: headerContentRect = null },
    } = useResizeObserver();
    const { height: headerHeight = 0 } = headerContentRect || {};

    const onChanged = useCallback(
        (dt) => {
            if (dt.scrollDirection !== null) {
                setDirection(dt.scrollDirection);
            }
        },
        [setDirection],
    );

    // The virtualizer
    const rowVirtualizer = useVirtualizer({
        onChange: (dt) => {
            onChanged(dt);
        },
        count: items != null ? items.length : 0,
        getScrollElement: () => scrollRef.current,
        estimateSize: (index) => {
            const item = items[index];
            return isString(item) ? 40 : 70;
        },
        paddingStart: headerHeight,
    });

    // const { items: categories } = useCategories({
    //     for: 'collections',
    // });

    const filters = useMemo(
        () => [
            {
                id: 'all',
                label: 'Tout',
            },
            {
                id: 'serie_podcast',
                label: 'Balados',
                current: currentFilter !== null && currentFilter.id === 'serie_podcast',
            },
            {
                id: 'serie',
                label: 'Vidéos',
                current: currentFilter !== null && currentFilter.id === 'serie',
            },
            {
                id: 'magazine',
                label: 'Magazines',
                current: currentFilter !== null && currentFilter.id === 'magazine',
            },
        ],
        [currentFilter],
    );

    const onClickFilter = useCallback(
        (e, newFilter) => {
            if (newFilter !== currentFilter) {
                setCurrentFilter(newFilter);
            } else {
                setCurrentFilter(null);
            }
        },
        [currentFilter],
    );

    return (
        <ListPanel
            {...props}
            title={
                <MenuSectionTitle>
                    <FormattedMessage
                        defaultMessage="Collections"
                        description="Menu section heading"
                    />
                </MenuSectionTitle>
            }
            onClickBack={onClickBack}
            onSearchChange={onSearchChange}
            search={search}
            ref={scrollRef}
            searchOnly={direction === 'forward'}
            headerRef={headerRef}
            header={
                <SearchFilters
                    filters={filters}
                    className={styles.filters}
                    onClickFilter={onClickFilter}
                />
            }
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <div
                className={styles.items}
                style={{
                    height: rowVirtualizer.getTotalSize() - headerHeight,
                }}
            >
                {rowVirtualizer.getVirtualItems().map((virtualItem) => {
                    const it = items[virtualItem.index];
                    return (
                        <div
                            key={virtualItem.key}
                            className={styles.item}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: `${virtualItem.size}px`,
                                transform: `translateY(${virtualItem.start}px)`,
                            }}
                        >
                            <CollectionHorizontalCard
                                {...it}
                                theme="menu"
                                size="tiny"
                                withBorder
                                className={styles.card}
                                key={`category-${it.id}`}
                            />
                        </div>
                    );
                })}
            </div>
            <div
                className={styles.end}
                ref={refEnd}
                style={{
                    height: 40,
                }}
            />
        </ListPanel>
    );
}

CollectionsPanel.propTypes = propTypes;
CollectionsPanel.defaultProps = defaultProps;

export default CollectionsPanel;
