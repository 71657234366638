import { useTracking } from '@folklore/tracking';
import { useQuery, keepPreviousData as previousPlaceholder } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';

import { useApi } from '../contexts/ApiContext';

export default function useSearch(search, opts = null) {
    const { minLength = 3, debounce = 500 } = opts || {};
    const api = useApi();
    const { keepPreviousData = true } = opts || {};
    const tracking = useTracking();
    const [searchQuery, setSearchQuery] = useState(
        search !== null && search.length >= minLength ? search : null,
    );
    useEffect(() => {
        if (search !== null && search.length < minLength) {
            return () => {};
        }
        if (search === null || searchQuery === null) {
            setSearchQuery(search);
            return () => {};
        }
        const timeout = setTimeout(() => {
            setSearchQuery(search);
        }, debounce);
        return () => {
            clearTimeout(timeout);
        };
    }, [search, minLength, debounce, tracking]);
    const {
        data: items = null,
        refetch,
        ...queryResult
    } = useQuery({
        queryKey: ['search', searchQuery],
        queryFn: ({ queryKey: [, searchParam], signal }) =>
            api.search.get(searchParam, null, { signal }).then((res) => {
                tracking.trackSearch(searchParam);
                return res;
            }),
        enabled: searchQuery !== null,
        suspense: false,
        placeholderData: keepPreviousData ? previousPlaceholder : null,
        ...opts,
    });

    const update = useCallback(() => {
        if (search !== searchQuery) {
            setSearchQuery(search);
        } else {
            refetch();
        }
    }, [search, searchQuery, refetch, tracking, setSearchQuery]);

    return {
        items,
        refetch,
        update,
        ...queryResult,
    };
}
