import { useSpring, easings } from '@react-spring/web';

function useMenuButtonsSpring({ visible = false, time = 250 } = {}) {
    const {
        opacity: buttonsOpacity,
        x: buttonsX,
        ...buttons
    } = useSpring({
        config: {
            duration: visible ? 0 : time * 2,
            easing: easings.easeOutBack,
        },
        delay: visible ? time * 0.5 : 0,
        from: { opacity: 0, x: 100 },
        to: { opacity: visible ? 1 : 0, x: visible ? 0 : 100 },
    });

    const style = {
        ...buttons,
        opacity: buttonsOpacity,
        transform: buttonsX.to((value) => `translateX(${value}px)`),
        pointerEvents: buttonsOpacity.to((v) => (v > 0 ? 'auto' : 'none')),
    };

    return {
        style,
    };
}

export default useMenuButtonsSpring;
