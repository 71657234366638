/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable no-unused-vars */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

function SearchIcon({ color, className }) {
    return (
        <svg
            width="17"
            height="18"
            viewBox="0 0 17 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M6.89 13.32C10.143 13.32 12.78 10.683 12.78 7.42999C12.78 4.17704 10.143 1.53999 6.89 1.53999C3.63704 1.53999 1 4.17704 1 7.42999C1 10.683 3.63704 13.32 6.89 13.32Z"
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path
                d="M16.22 16.76L11.05 11.59"
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
            />
        </svg>
    );
}

SearchIcon.propTypes = propTypes;
SearchIcon.defaultProps = defaultProps;

export default SearchIcon;
