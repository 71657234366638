/* eslint-disable react/jsx-indent */

/* eslint-disable react/jsx-props-no-spreading */
import { useIntersectionObserver, useResizeObserver } from '@folklore/hooks';
import { useVirtualizer } from '@tanstack/react-virtual';
import classNames from 'classnames';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import useDocumentsInfinite from '../../hooks/useDocumentsInfinite';

import DocumentHorizontalCard from '../cards/DocumentHorizontalCard';
import MenuSectionTitle from '../typography/MenuSection';
import ListPanel from './ListPanel';

import styles from '../../styles/panels/categories-panel.module.css';

const propTypes = {
    className: PropTypes.string,
    closePanel: PropTypes.func.isRequired,
    type: PropTypes.string,
};

const defaultProps = {
    type: null,
    className: null,
};

function DocumentsPanel({ type, className, closePanel, ...props }) {
    const [search, setSearch] = useState(null);
    const [direction, setDirection] = useState(null);

    const {
        pages = null,
        isFetching,
        isFetchingNextPage,
        fetchNextPage,
    } = useDocumentsInfinite(
        search !== null
            ? {
                  search,
                  type,
              }
            : {
                  type,
              },
    );

    const onSearchChange = useCallback(
        (newSearch) => {
            setSearch(newSearch);
            setDirection('backward');
        },
        [setSearch],
    );

    const onClickBack = useCallback(() => {
        closePanel();
    }, [closePanel]);

    const {
        ref: refEnd,
        entry: { isIntersecting: endInView = false },
    } = useIntersectionObserver({
        disabled: isFetching || isFetchingNextPage,
    });

    useEffect(() => {
        if (endInView && !isFetching && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [isFetching, isFetchingNextPage, endInView]);

    // const withLetters = search === null;

    const items = useMemo(() => {
        if (pages === null) {
            return null;
        }
        return pages.reduce((allItems, { data: pageItems }) => [...allItems, ...pageItems], []);
    }, [pages]);

    // The scrollable element for your list
    const scrollRef = useRef();
    const {
        ref: headerRef,
        entry: { contentRect: headerContentRect = null },
    } = useResizeObserver();
    const { height: headerHeight = 0 } = headerContentRect || {};

    const onChanged = useCallback(
        (dt) => {
            if (dt.scrollDirection !== null) {
                setDirection(dt.scrollDirection);
            }
        },
        [setDirection],
    );

    // The virtualizer
    const rowVirtualizer = useVirtualizer({
        onChange: (dt) => {
            onChanged(dt);
        },
        count: items != null ? items.length : 0,
        getScrollElement: () => scrollRef.current,
        estimateSize: (index) => {
            const item = items[index];
            return isString(item) ? 40 : 100;
        },
        paddingStart: headerHeight,
    });

    let title;
    if (type === 'micromag') {
        title = <FormattedMessage defaultMessage="Micromags" description="Menu section heading" />;
    } else if (type === 'video') {
        title = <FormattedMessage defaultMessage="Vidéos" description="Menu section heading" />;
    } else if (type === 'article') {
        title = <FormattedMessage defaultMessage="Articles" description="Menu section heading" />;
    }

    return (
        <ListPanel
            {...props}
            title={<MenuSectionTitle>{title}</MenuSectionTitle>}
            onClickBack={onClickBack}
            onSearchChange={onSearchChange}
            search={search}
            ref={scrollRef}
            searchOnly={direction === 'forward'}
            headerRef={headerRef}
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <div
                className={styles.items}
                style={{
                    height: rowVirtualizer.getTotalSize() - headerHeight,
                }}
            >
                {rowVirtualizer.getVirtualItems().map((virtualItem) => {
                    const it = items[virtualItem.index];
                    return (
                        <div
                            key={virtualItem.key}
                            className={styles.item}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: `${virtualItem.size}px`,
                                transform: `translateY(${virtualItem.start}px)`,
                            }}
                        >
                            <DocumentHorizontalCard
                                {...it}
                                theme="menu"
                                className={styles.category}
                                key={`${it.type}-${it.id}`}
                            />
                        </div>
                    );
                })}
            </div>
            <div
                className={styles.end}
                ref={refEnd}
                style={{
                    height: 40,
                }}
            />
        </ListPanel>
    );
}

DocumentsPanel.propTypes = propTypes;
DocumentsPanel.defaultProps = defaultProps;

export default DocumentsPanel;
