import { useSpring, easings, useChain, useSpringRef } from '@react-spring/web';

function useSearchPanelSpring({ opened = false, cancelButtonWidth = 0, time = 500 } = {}) {
    const searchFieldRef = useSpringRef();
    const { width: searchFieldWidth } = useSpring({
        ref: searchFieldRef,
        config: {
            duration: time,
            easing: easings.easeOutCubic,
        },
        width: opened ? 1 : 0,
    });

    const cancelButtonRef = useSpringRef();
    const { x: cancelButtonX, ...cancelButton } = useSpring({
        ref: cancelButtonRef,
        config: {
            duration: time,
            easing: easings.easeOutCubic,
        },
        opacity: opened ? 1 : 0,
        x: opened ? -cancelButtonWidth : 0,
    });

    const sectionRef = useSpringRef();
    const { y: sectionY, ...section } = useSpring({
        ref: sectionRef,
        config: {
            duration: time * 2,
            easing: easings.easeOutCubic,
        },
        from: { y: 20, opacity: 0 },
        to: { y: opened ? 0 : 20, opacity: opened ? 1 : 0 },
    });

    useChain(
        opened
            ? [searchFieldRef, cancelButtonRef, sectionRef]
            : [sectionRef, searchFieldRef, cancelButtonRef],
        [0, 0.1, 0.1],
    );

    return {
        searchFieldStyle: {
            width: searchFieldWidth.to((value) => `calc(100% - ${value * cancelButtonWidth}px + ${value * 10}px)`),
        },
        cancelButtonStyle: {
            ...cancelButton,
            transform: cancelButtonX.to((value) => `translateX(${value}px)`),
        },
        sectionStyle: {
            ...section,
            transform: sectionY.to((value) => `translateY(${value}px)`),
        },
    };
}

export default useSearchPanelSpring;
