/* eslint-disable react/no-array-index-key */

/* eslint-disable react/jsx-props-no-spreading */
// import classNames from 'classnames';
// import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import usePlaceholderItems from '../../hooks/usePlaceholderItems';
import { useTimelineFuture } from '../../hooks/useTimeline';

import { useTouchScreen } from '../../contexts/DeviceContext';
import DocumentsList from './DocumentsList';

const propTypes = {
    count: PropTypes.number,
};

const defaultProps = {
    count: 6,
};

function SearchSuggestionsList({ count, ...props }) {
    const { items } = useTimelineFuture(count, {
        snippet: true,
    });
    const placeholderItems = usePlaceholderItems(count);

    const isTouchScreen = useTouchScreen();

    return (
        <DocumentsList
            cardType="vertical"
            cardTheme="suggestion"
            cardRatio="square"
            presentation={isTouchScreen ? 'row' : 'grid'}
            cardWidth={120}
            columns={3}
            withoutLoader
            {...props}
            items={items || placeholderItems}
        />
    );
}

SearchSuggestionsList.propTypes = propTypes;
SearchSuggestionsList.defaultProps = defaultProps;

export default SearchSuggestionsList;
