/* eslint-disable react/jsx-indent */

/* eslint-disable react/jsx-props-no-spreading */
import { animated, useTransition } from '@react-spring/web';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import AuthorHorizontalCard from '../cards/AuthorHorizontalCard';
import DocumentHorizontalCard from '../cards/DocumentHorizontalCard';
import MenuSubSectionTitle from '../typography/MenuSubSection';

import styles from '../../styles/lists/search-results-list.module.css';
import { getTrackingQuery } from '../../lib/utils';

const propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            age: PropTypes.number,
        }),
    ),
    recommendationId: PropTypes.string,
};

const defaultProps = {
    className: null,
    label: null,
    items: null,
    recommendationId: null,
};

function SearchResultsList({ className, items, recommendationId }) {
    const transitions = useTransition(items, {
        keys: ({ type, id }) => `${type}_${id}`,
        from: { height: 40 },
        enter: { height: 0 },
    });

    const linkQuery = useMemo(
        () => (recommendationId !== null ? getTrackingQuery({ recommendationId }) : null),
        [recommendationId],
    );

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            {transitions((style, it) => {
                const { type } = it;
                if (type === 'author') {
                    return (
                        <div>
                            <animated.div style={style} />
                            <AuthorHorizontalCard
                                linkQuery={linkQuery}
                                {...it}
                                theme="menu"
                                className={styles.item}
                            />
                        </div>
                    );
                }
                return (
                    <div>
                        <animated.div style={style} />
                        <DocumentHorizontalCard
                            linkQuery={linkQuery}
                            {...it}
                            theme="menu"
                            className={styles.item}
                        />
                    </div>
                );
            })}
        </div>
    );
}

SearchResultsList.propTypes = propTypes;
SearchResultsList.defaultProps = defaultProps;

export default SearchResultsList;
