import { useSpring, easings, useChain, useSpringRef } from '@react-spring/web';

function useMainMenuSpring({
    searchOpened = false,
    searchOffsetY = 0,
    searchFormHeight = 60,
    containerHeight = 200,
    time = 500,
} = {}) {
    const searchPanelRef = useSpringRef();
    const { y: searchPanelY, ...searchPanel } = useSpring({
        ref: searchPanelRef,
        config: {
            duration: time,
            easing: easings.easeOutCubic,
        },
        y: searchOpened ? -searchOffsetY : 0,
        height: searchOpened ? containerHeight : searchFormHeight,
    });

    const sectionRef = useSpringRef();
    const { opacity: sectionOpacity, ...section } = useSpring({
        ref: sectionRef,
        config: {
            duration: time,
        },
        opacity: searchOpened ? 0 : 1,
    });

    useChain(searchOpened ? [searchPanelRef, sectionRef] : [searchPanelRef, sectionRef], [
        0,
        searchOpened ? 0 : 0.35,
    ]);

    return {
        searchPanelStyle: {
            ...searchPanel,
            transform: searchPanelY.to((value) => `translateY(${value}px)`),
        },
        sectionStyle: {
            ...section,
            opacity: sectionOpacity,
            pointerEvents: sectionOpacity.to((v) => (v > 0 ? 'auto' : 'none')),
        },
    };
}

export default useMainMenuSpring;
