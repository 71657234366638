import { useInfiniteQuery, keepPreviousData as previousPlaceholder } from '@tanstack/react-query';

import { useApi } from '../contexts/ApiContext';

export default function useTopicsInfinite(query = null, opts = null) {
    const api = useApi();
    const { keepPreviousData = true } = opts || {};
    const { data = null, ...queryResult } = useInfiniteQuery({
        queryKey: ['topics', query],
        initialPageParam: 1,
        queryFn: ({ pageParam = 1, queryKey: [, queryParam], signal }) =>
            api.taxonomies.topics(queryParam, pageParam, null, { signal }),
        getNextPageParam: ({ pagination: { page: currentPage = 1, last_page: lastPage = -1 } }) => {
            if (lastPage === currentPage) {
                return undefined;
            }
            return currentPage + 1;
        },
        suspense: false,
        placeholderData: keepPreviousData ? previousPlaceholder : null,
        ...opts,
    });

    const { pages = null, pageParams = null } = data || {};

    return {
        pages,
        pageParams,
        ...queryResult,
    };
}
