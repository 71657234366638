/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable no-unused-vars */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function SearchRecent({ className }) {
    return (
        <svg
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <g>
                <ellipse
                    cx="16.4489"
                    cy="15.9488"
                    rx="15.9485"
                    ry="15.9485"
                    fill="url(#paint0_linear_5301_16070)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.77995 13.846C8.77995 10.7908 11.2567 8.31407 14.3119 8.31407C17.3671 8.31407 19.8438 10.7908 19.8438 13.846C19.8438 16.9012 17.3671 19.378 14.3119 19.378C11.2567 19.378 8.77995 16.9012 8.77995 13.846ZM14.3119 6.05151C10.0071 6.05151 6.5174 9.54123 6.5174 13.846C6.5174 18.1508 10.0071 21.6405 14.3119 21.6405C16.0562 21.6405 17.6666 21.0676 18.9653 20.0996L24.0666 25.2008L25.6664 23.601L20.5652 18.4998C21.5334 17.201 22.1064 15.5904 22.1064 13.846C22.1064 9.54123 18.6167 6.05151 14.3119 6.05151Z"
                    fill="url(#paint1_linear_5301_16070)"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_5301_16070"
                    x1="16.4489"
                    y1="0.00034523"
                    x2="16.222"
                    y2="27.4896"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CBCBCB" />
                    <stop offset="1" stopColor="#D9D9D9" stopOpacity="0.42" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_5301_16070"
                    x1="8.62379"
                    y1="6.05151"
                    x2="27.7731"
                    y2="26.541"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E4CEFF" />
                    <stop offset="1" stopColor="#00FFC2" />
                </linearGradient>
            </defs>
        </svg>
    );
}

SearchRecent.propTypes = propTypes;
SearchRecent.defaultProps = defaultProps;

export default SearchRecent;
