/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable no-unused-vars */
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSpring, animated, useSpringRef, easings, useChain } from '@react-spring/web';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';

import FieldClose from '../icons/FieldCloseIcon';
import SearchIcon from '../icons/SearchIcon';
import TextField from './TextField';

import styles from '../../styles/fields/search-field.module.css';

const propTypes = {
    value: PropTypes.string,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
};

const defaultProps = {
    value: null,
    loading: false,
    onChange: null,
    className: null,
    inputClassName: null,
};

function SearchField({ value, loading, onChange, className, inputClassName, ...props }) {
    const onClickClear = useCallback(() => {
        onChange(null);
    }, [onChange]);

    const closeStyle = useSpring({
        config: {
            duration: 250,
            easing: easings.easeOutCubic,
        },
        delay: value !== null ? 100 : 0,
        to: { x: value !== null ? 0 : 20, opacity: value !== null && !loading ? 1 : 0 },
    });

    const loadingStyle = useSpring({
        config: {
            duration: 250,
            easing: easings.easeOutCubic,
        },
        delay: loading ? 100 : 0,
        to: { x: loading || value !== null ? 0 : 20, opacity: loading && value !== null ? 1 : 0 },
    });

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.hasValue]: value !== null,
                    [styles.isLoading]: loading,
                    [className]: className !== null,
                },
            ])}
        >
            <SearchIcon className={styles.searchIcon} />
            <TextField
                value={value}
                onChange={onChange}
                className={classNames([
                    styles.input,
                    {
                        [inputClassName]: inputClassName !== null,
                    },
                ])}
                {...props}
            />
            <animated.button
                type="button"
                className={styles.closeButton}
                onClick={onClickClear}
                style={closeStyle}
            >
                <FieldClose className={styles.closeIcon} />
            </animated.button>
            <animated.div className={styles.loading} style={loadingStyle}>
                <FontAwesomeIcon icon={faCircleNotch} spin className={styles.loadingIcon} />
            </animated.div>
        </div>
    );
}

SearchField.propTypes = propTypes;
SearchField.defaultProps = defaultProps;

export default SearchField;
