/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';
import camelCase from 'lodash/camelCase';

import SearchRecentIcon from '../icons/SearchRecentIcon';
import CardTitle from '../typography/CardTitle';
import HorizontalCard from './HorizontalCard';

import styles from '../../styles/cards/search-horizontal-card.module.css';

const propTypes = {
    query: PropTypes.string,
    size: AppPropTypes.cardSize,
    withBorder: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

const defaultProps = {
    query: null,
    size: null,
    withBorder: false,
    className: null,
    onClick: null,
};

function SearchHorizontalCard({ query, size, withBorder, className, onClick }) {
    const url = useUrlGenerator();
    return (
        <HorizontalCard
            href={`${url('search')}?${queryString.stringify({
                q: query,
            })}`}
            onClick={onClick}
            thumbnail={<SearchRecentIcon className={styles.icon} />}
            className={classNames([
                styles.container,
                {
                    [styles.withBorder]: withBorder,
                },
                styles[camelCase(size)],
                className,
            ])}
            thumbnailRatio={null}
            thumbnailClassName={styles.thumbnail}
            labelClassName={styles.label}
        >
            <CardTitle className={styles.title}>{query}</CardTitle>
        </HorizontalCard>
    );
}

SearchHorizontalCard.propTypes = propTypes;
SearchHorizontalCard.defaultProps = defaultProps;

export default SearchHorizontalCard;
