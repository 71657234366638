/* eslint-disable react/jsx-props-no-spreading */
import { useVisualViewport } from '@folklore/hooks';
import { useRoutes, useUrlGenerator } from '@folklore/routes';
import { useSpring, animated } from '@react-spring/web';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearch } from 'wouter';

import { useGlobalBrand } from '../../contexts/LayoutContext';
import MainMenuLayout from '../layouts/MainMenuLayout';
import AuthorsPanel from '../panels/AuthorsPanel';
import CategoriesPanel from '../panels/CategoriesPanel';
import CollectionsPanel from '../panels/CollectionsPanel';
import DocumentsPanel from '../panels/DocumentsPanel';
import MainMenuPanel from '../panels/MainMenuPanel';
import TopicsPanel from '../panels/TopicsPanel';

import styles from '../../styles/menus/main-menu.module.css';

const propTypes = {
    opened: PropTypes.bool,
    lockBodyScroll: PropTypes.bool,
    closeMenu: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    opened: false,
    lockBodyScroll: false,
    closeMenu: null,
    className: null,
};

function MainMenu({ opened, lockBodyScroll, closeMenu, className }) {
    const brand = useGlobalBrand();
    const url = useUrlGenerator();

    const [menuPanel, setMenuPanel] = useState(null);
    const [searchQuery, setSearchQuery] = useState(null);
    const searchOpened = menuPanel === 'search';
    const closePanel = useCallback(() => setMenuPanel(null), [setMenuPanel]);
    const openPanel = useCallback(
        (newPanel) => {
            setMenuPanel(newPanel);
        },
        [setMenuPanel],
    );
    const onSearchOpen = useCallback(() => {
        setMenuPanel('search');
    }, [setMenuPanel]);

    const onSearchClose = useCallback(() => {
        closePanel();
    }, [closePanel]);

    const onSearchChange = useCallback(
        (newQuery) => {
            setSearchQuery(newQuery);
        },
        [setSearchQuery],
    );

    useEffect(() => {
        if (!opened && menuPanel !== null) {
            setMenuPanel(null);
        }
    }, [opened, menuPanel, setMenuPanel]);

    useEffect(() => {
        if (!searchOpened && searchQuery !== null) {
            setSearchQuery(null);
        }
    }, [searchOpened, searchQuery, setSearchQuery]);

    const [visiblePanel, setVisiblePanel] = useState(null);

    const currentPanel = menuPanel || visiblePanel;
    const isSectionPanel =
        currentPanel !== null && currentPanel !== 'search' && currentPanel !== 'menu';
    const finalPanel = isSectionPanel ? currentPanel : null;

    const mainPanelStyle = useSpring({
        x: isSectionPanel ? -100 : 0,
    });
    const panelStyle = useSpring({
        x: isSectionPanel ? 0 : 100,
        onResolve: () => {
            setVisiblePanel(menuPanel);
        },
    });
    const { height: viewportHeight } = useVisualViewport();

    const panelRef = useRef(null);

    // useEffect(() => {
    //     if (panelRef.current === null) {
    //         return () => {};
    //     }
    //     if (opened && lockBodyScroll) {
    //         disableBodyScroll(panelRef.current);
    //     }
    //     return () => {
    //         if (opened && lockBodyScroll) {
    //             enableBodyScroll(panelRef.current);
    //         }
    //     };
    // }, [opened, lockBodyScroll, finalPanel]);

    return (
        <MainMenuLayout
            brand={brand}
            className={classNames([styles.container, { [className]: className !== null }])}
            closeMenu={closeMenu}
            withButtons={!searchOpened}
        >
            <animated.div
                style={{
                    transform: panelStyle.x.to((value) => `translateX(${value}%)`),
                    height: viewportHeight,
                }}
                className={styles.panelContainer}
            >
                {finalPanel === 'authors' ? (
                    <AuthorsPanel
                        closePanel={closePanel}
                        className={classNames([styles.panel, 'hide-scrollbar'])}
                        ref={panelRef}
                    />
                ) : null}
                {finalPanel === 'categories' ? (
                    <CategoriesPanel
                        title="Catégories"
                        closePanel={closePanel}
                        className={classNames([styles.panel, 'hide-scrollbar'])}
                        ref={panelRef}
                    />
                ) : null}
                {finalPanel === 'micromags' ? (
                    <DocumentsPanel
                        type="micromag"
                        closePanel={closePanel}
                        className={classNames([styles.panel, 'hide-scrollbar'])}
                        ref={panelRef}
                    />
                ) : null}
                {finalPanel === 'videos' ? (
                    <DocumentsPanel
                        type="video"
                        closePanel={closePanel}
                        className={classNames([styles.panel, 'hide-scrollbar'])}
                        ref={panelRef}
                    />
                ) : null}
                {finalPanel === 'articles' ? (
                    <DocumentsPanel
                        type="article"
                        closePanel={closePanel}
                        className={classNames([styles.panel, 'hide-scrollbar'])}
                        ref={panelRef}
                    />
                ) : null}
                {finalPanel === 'podcasts' ? (
                    <DocumentsPanel
                        type="podcast"
                        closePanel={closePanel}
                        className={classNames([styles.panel, 'hide-scrollbar'])}
                        ref={panelRef}
                    />
                ) : null}
                {finalPanel === 'collections' ? (
                    <CollectionsPanel
                        title="Collections"
                        closePanel={closePanel}
                        className={classNames([styles.panel, 'hide-scrollbar'])}
                        ref={panelRef}
                    />
                ) : null}
                {finalPanel === 'topics' ? (
                    <TopicsPanel
                        title="Sujets"
                        closePanel={closePanel}
                        className={classNames([styles.panel, 'hide-scrollbar'])}
                        ref={panelRef}
                    />
                ) : null}
            </animated.div>
            <animated.div
                style={{
                    transform: mainPanelStyle.x.to((value) => `translateX(${value}%)`),
                }}
                className={styles.panelContainer}
            >
                <MainMenuPanel
                    brand={brand}
                    searchOpened={searchOpened}
                    searchQuery={searchQuery}
                    onSearchOpen={onSearchOpen}
                    onSearchClose={onSearchClose}
                    onSearchChange={onSearchChange}
                    openPanel={openPanel}
                    closePanel={closePanel}
                    closeMenu={closeMenu}
                    className={classNames([styles.panel, 'hide-scrollbar'])}
                    ref={finalPanel === null ? panelRef : null}
                />
            </animated.div>
        </MainMenuLayout>
    );
}

MainMenu.propTypes = propTypes;
MainMenu.defaultProps = defaultProps;

export default MainMenu;
