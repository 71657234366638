/* eslint-disable jsx-a11y/anchor-is-valid */

/* eslint-disable react/jsx-props-no-spreading */
import { useResizeObserver } from '@folklore/hooks';
import { useUrlGenerator, useRouteMatcher } from '@folklore/routes';
// import * as AppPropTypes from '../../lib/PropTypes';
import { getComponentFromName } from '@folklore/utils';
import { animated } from '@react-spring/web';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useMenuPanels } from '../../hooks/useMenuPanel';
import * as AppPropTypes from '../../lib/PropTypes';

import useMainMenuPanelSpring from '../../animations/useMainMenuPanelSpring';
import { useTouchScreen } from '../../contexts/DeviceContext';
import { useBrands } from '../../contexts/SiteContext';
import MenuHeader from '../headers/MenuHeader';
import MenuSectionList from '../lists/MenuSectionList';
import * as ListComponents from '../lists/index';
import LinksMenu from '../menus/LinksMenu';
import SearchPanel from './SearchPanel';

import styles from '../../styles/panels/main-menu-panel.module.css';

const propTypes = {
    brand: AppPropTypes.brand,
    sections: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
            icon: PropTypes.string,
            list: PropTypes.string,
        }),
    ),
    searchOpened: PropTypes.bool,
    searchQuery: PropTypes.string,
    onSearchOpen: PropTypes.func,
    onSearchClose: PropTypes.func,
    onSearchChange: PropTypes.func,
    openPanel: PropTypes.func.isRequired,
    closeMenu: PropTypes.func.isRequired,
    className: PropTypes.string,
    containerRef: AppPropTypes.ref,
};

const defaultProps = {
    brand: null,
    sections: [
        // {
        //     id: 'categories',
        //     route: 'categories',
        //     icon: 'category',
        //     list: 'categories',
        //     presentation: 'inline',
        //     count: 4,
        //     query: {
        //         for: 'documents',
        //         order: 'count',
        //     },
        //     brandScoped: true,
        //     cardType: 'horizontal',
        //     cardTheme: 'pill',
        //     cardSize: 'small',
        //     cardImageLoading: 'lazy',
        //     withoutViewAll: true,
        //     className: styles.brandSection,
        // },
        {
            id: 'search',
        },
        // {
        //     id: 'authors',
        //     route: 'authors',
        //     list: 'authors',
        //     loading: 'lazy',
        //     count: 4,
        //     query: {
        //         for: 'documents',
        //         order: 'count',
        //         person: true,
        //     },
        //     brandScoped: true,
        //     presentation: 'grid',
        //     columns: 4,
        //     cardType: 'vertical',
        //     cardSize: 'small',
        //     withoutViewAll: true,
        //     className: styles.brandSection,
        // },

        // {
        //     id: 'popuplar',
        //     list: 'documents',
        //     loading: 'lazy',
        //     title: (
        //         <FormattedMessage
        //             defaultMessage="Populaire en ce moment"
        //             description="Menu section heading"
        //         />
        //     ),
        //     count: 6,
        //     query: {
        //         popular: 'month',
        //     },
        //     brandScoped: true,
        //     presentation: 'grid',
        //     columns: 1,
        //     cardType: 'horizontal',
        //     // cardTheme: null,
        //     // cardTheme: 'suggestion',
        //     cardRatio: 'square',
        //     cardSize: 'tiny',
        //     cardResponsive: true,
        //     cardWithout: 'time',
        //     withoutViewAll: true,
        //     className: classNames([styles.brandSection, styles.popularLinks]),
        // },

        {
            id: 'brands',
            list: 'brands',
            className: styles.brandsSection,
            // title: (
            // <FormattedMessage defaultMessage="Nos médias" description="Menu section heading" />
            // ),
            cardRatio: 12 / 5,
            columns: 1,
            cardType: 'horizontal',
            cardTheme: 'menu-reverse',
            presentation: 'grid',
        },
        {
            id: 'authors',
            route: 'authors',
            list: 'authors',
            loading: 'lazy',
            title: (
                <FormattedMessage
                    defaultMessage="Tous les auteurs"
                    description="Menu section heading"
                />
            ),
            count: 12,
            query: {
                for: 'documents',
                order: 'count',
                person: true,
            },
            presentation: 'grid',
            columns: 4,
            cardType: 'vertical',
            cardSize: 'small',
            cardImageLoading: 'lazy',
        },
        {
            id: 'micromags',
            route: 'micromags',
            title: (
                <FormattedMessage defaultMessage="Micromags" description="Menu section heading" />
            ),
            icon: 'micromag',
            list: 'documents',
            loading: 'lazy',
            type: 'micromag',
            query: {
                collection: 'micromag-urbania',
            },
            cardRatio: 'micromag',
            cardType: 'over',
            cardSize: 'small',
            cardTheme: 'without_text',
            cardWithout: ['brand_icon', 'type_icon'],
            presentation: 'grid',
            columns: 3,
            count: 9,
            cardImageLoading: 'lazy',
        },
        {
            id: 'videos',
            route: 'videos',
            title: (
                <FormattedMessage
                    defaultMessage="Séries vidéos"
                    description="Menu section heading"
                />
            ),
            query: {
                order: 'new',
                type: 'serie',
                handle: [
                    'entre-moi-et-moi',
                    'passes-croises',
                    'comment-faire-lamour',
                    'courrier-recommande',
                    'tel-que-vu-sur-le-web',
                    'portrait',
                ],
            },
            presentation: 'grid',
            icon: 'collection',
            list: 'collections',
            loading: 'lazy',
            cardType: 'over',
            cardRatio: 'vertical',
            cardWithout: ['text'],
            columns: 3,
            // cardTheme: 'outline',
            // columns: 1,
            count: 6,
            cardImageLoading: 'lazy',
        },
        {
            id: 'podcasts',
            route: 'podcasts',
            title: <FormattedMessage defaultMessage="Balados" description="Menu section heading" />,
            query: {
                order: 'new',
                type: 'serie_podcast',
            },
            presentation: 'grid',
            icon: 'collection',
            list: 'collections',
            loading: 'lazy',
            cardType: 'over',
            cardRatio: 'square',
            cardWithout: ['text'],
            columns: 3,
            // cardTheme: 'outline',
            // columns: 1,
            count: 6,
            cardImageLoading: 'lazy',
        },
        // {
        //     id: 'videos',
        //     route: 'videos',
        //     title: <FormattedMessage defaultMessage="Vidéos" description="Menu section heading" />,
        //     icon: 'video',
        //     list: 'documents',
        //     loading: 'lazy',
        //     type: 'video',
        //     cardType: 'vertical',
        //     cardRatio: 'vertical',
        //     cardSize: 'small',
        //     columns: 3,
        //     count: 6,
        //     presentation: 'grid',
        //     // cardSize: 'tiny',
        //     cardTheme: 'suggestion',
        //     cardImageLoading: 'lazy',
        // },
        // {
        //     id: 'articles',
        //     route: 'articles',
        //     title: (
        //         <FormattedMessage defaultMessage="Articles" description="Menu section heading" />
        //     ),
        //     icon: 'article',
        //     list: 'documents',
        //     loading: 'lazy',
        //     type: 'article',
        //     cardType: 'horizontal',
        //     columns: 1,
        //     count: 6,
        //     presentation: 'grid',
        //     cardWithout: 'time',
        //     // cardSize: 'small',
        //     cardSize: 'tiny',
        //     cardImageLoading: 'lazy',
        // },
        {
            id: 'collections',
            route: 'collections',
            title: (
                <FormattedMessage defaultMessage="Collections" description="Menu section heading" />
            ),
            query: {
                order: 'new',
                type: 'collection',
                handle: [
                    'vos-grands-projets-de-vie',
                    'briser-les-tabous',
                    'largent-au-feminin',
                    'investir',
                ],
            },
            presentation: 'grid',
            icon: 'collection',
            list: 'collections',
            loading: 'lazy',
            cardType: 'over',
            cardSize: 'small',
            cardRatio: 'horizontal',
            cardTheme: 'menu',
            // columns: 1,
            count: 6,
            // columns: 1,
            cardImageLoading: 'lazy',
        },
        {
            id: 'categories',
            route: 'categories',
            title: (
                <FormattedMessage
                    defaultMessage="Toutes les catégories"
                    description="Menu section heading"
                />
            ),
            icon: 'category',
            list: 'categories',
            presentation: 'inline',
            count: 12,
            query: {
                for: 'documents',
                order: 'count',
            },
            cardType: 'horizontal',
            cardTheme: 'pill',
            cardSize: 'small',
            cardImageLoading: 'lazy',
        },
        {
            id: 'topics',
            route: 'topics',
            title: (
                <FormattedMessage
                    defaultMessage="Sujets du moment"
                    description="Menu section heading"
                />
            ),
            icon: 'category',
            list: 'topics',
            presentation: 'inline',
            count: 12,
            query: {
                for: 'documents',
                order: 'count',
            },
            cardType: 'horizontal',
            cardTheme: 'pill',
            cardSize: 'small',
            cardImageLoading: 'lazy',
        },
    ],
    searchOpened: false,
    searchQuery: null,
    onSearchOpen: null,
    onSearchClose: null,
    onSearchChange: null,
    className: null,
    containerRef: null,
};

function MainMenuPanel({
    brand,
    sections,
    searchOpened,
    searchQuery,
    onSearchOpen,
    onSearchClose,
    onSearchChange,
    openPanel,
    closeMenu,
    className,
    containerRef,
}) {
    const url = useUrlGenerator();
    const menuPanels = useMenuPanels();
    const routeMatcher = useRouteMatcher();
    const brands = useBrands();
    const {
        id: brandId = null,
        slug: brandSlug = null,
        handle: brandHandle = null,
        default: brandIsDefault = false,
    } = brand || {};

    const {
        ref: resizeRef,
        entry: { contentRect: containerContentRect },
    } = useResizeObserver();
    const { height: containerHeight = null } = containerContentRect || {};
    const {
        ref: searchPanelRef,
        entry: { contentRect: searchPanelContentRect },
    } = useResizeObserver();
    const {
        ref: searchFormRef,
        entry: { contentRect: searchFormContentRect },
    } = useResizeObserver();
    const { height: searchFormHeight = null } = searchFormContentRect || {};
    const [searchPanelTop, setSearchPanelTop] = useState(0);
    useEffect(() => {
        setSearchPanelTop(searchPanelRef.current.getBoundingClientRect().top);
    }, [searchPanelContentRect]);
    const { searchPanelStyle, sectionStyle } = useMainMenuPanelSpring({
        searchOffsetY: searchPanelTop,
        containerHeight,
        searchFormHeight,
        searchOpened,
    });

    const onClickPanelLink = useCallback(
        (e) => {
            const { pathname } = new URL(e.currentTarget.href);
            const panel =
                Object.keys(menuPanels).find((key) => {
                    const [matches = false] = routeMatcher(menuPanels[key], pathname);
                    return matches;
                }) || null;
            if (panel !== null) {
                e.preventDefault();
                openPanel(panel);
            } else {
                closeMenu();
            }
        },
        [menuPanels, routeMatcher, openPanel, closeMenu],
    );

    const onSearchFocus = useCallback(() => {
        setSearchPanelTop(searchPanelRef.current.getBoundingClientRect().top);
        if (onSearchOpen !== null) {
            onSearchOpen();
        }
    }, [onSearchOpen]);
    const onSearchCancel = useCallback(() => {
        if (onSearchClose !== null) {
            onSearchClose();
        }
    }, [onSearchClose]);

    const isTouchScreen = useTouchScreen();
    const isGrid = !isTouchScreen;

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles[brandHandle]]: brandHandle !== null,
                    [styles.searchOpened]: searchOpened,
                    [className]: className !== null,
                },
            ])}
            ref={(ref) => {
                resizeRef.current = ref;
                if (containerRef !== null) {
                    // eslint-disable-next-line no-param-reassign
                    containerRef.current = ref;
                }
            }}
        >
            <MenuHeader brand={brand} className={styles.header} />

            {sections.map(
                ({
                    id,
                    title,
                    icon,
                    list,
                    route = null,
                    className: sectionClassName = null,
                    listClassName: sectionListClassName = null,
                    withoutViewAll = false,
                    brandScoped = false,
                    query = null,
                    ...listProps
                }) => {
                    if (id === 'brands' && brands.length === 1) {
                        return null;
                    }
                    if (id === 'search') {
                        return (
                            <div
                                className={styles.searchPanelContainer}
                                style={{
                                    height: searchFormHeight,
                                }}
                                ref={searchPanelRef}
                            >
                                <animated.div
                                    className={styles.searchPanelSlider}
                                    style={searchPanelStyle}
                                >
                                    <SearchPanel
                                        opened={searchOpened}
                                        query={searchQuery}
                                        formRef={searchFormRef}
                                        onFocus={onSearchFocus}
                                        onCancel={onSearchCancel}
                                        onChange={onSearchChange}
                                        className={styles.searchPanel}
                                        contentClassName={styles.searchPanelContent}
                                    />
                                </animated.div>
                            </div>
                        );
                    }
                    const ListComponent = getComponentFromName(ListComponents, list);
                    return ListComponent !== null ? (
                        <animated.div
                            className={classNames([styles.section, sectionClassName])}
                            style={sectionStyle}
                        >
                            <MenuSectionList
                                key={`list-${id}`}
                                title={title}
                                link={route !== null ? url(route) : null}
                                icon={icon}
                                withoutViewAll={withoutViewAll}
                                onClickLink={onClickPanelLink}
                            >
                                <ListComponent
                                    presentation={isGrid ? 'grid' : 'row'}
                                    count={isGrid ? 4 : 12}
                                    columns={2}
                                    cardTheme="menu"
                                    query={
                                        brandScoped && !brandIsDefault
                                            ? {
                                                  ...query,
                                                  brand: brandId,
                                              }
                                            : query
                                    }
                                    {...listProps}
                                    className={classNames([styles.list, sectionListClassName])}
                                    itemsClassName={styles.items}
                                />
                            </MenuSectionList>
                        </animated.div>
                    ) : null;
                },
            )}

            <LinksMenu
                items={[
                    {
                        id: 'a-propos',
                        href: '/a-propos',
                        label: 'À propos',
                    },
                    {
                        id: 'publicite',
                        href: '/publicite',
                        label: 'Publicité',
                    },
                ]}
                className={styles.linksMenu}
                itemClassName={styles.linkItem}
                linkClassName={styles.linkLink}
            />

            <LinksMenu
                items={[
                    {
                        id: 'privacy',
                        href: 'https://www.iubenda.com/privacy-policy/49318682',
                        label: 'Politique de confidentialité',
                        external: true,
                    },
                    {
                        id: 'privacy',
                        href: 'https://www.iubenda.com/privacy-policy/49318682/cookie-policy',
                        label: 'Politique des cookies',
                        external: true,
                    },
                ]}
                className={styles.linksMenu}
                itemClassName={styles.linkItem}
                linkClassName={styles.linkLink}
            />
        </div>
    );
}

MainMenuPanel.propTypes = propTypes;
MainMenuPanel.defaultProps = defaultProps;

export default React.forwardRef((props, ref) => <MainMenuPanel {...props} containerRef={ref} />);
