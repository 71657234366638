import { useEffect, useMemo, useState } from 'react';

function useRecentSearch(currentSearch) {
    const initialItems = useMemo(() => {
        const recentSearch = localStorage.getItem('recentSearch') || null;
        return (recentSearch !== null ? JSON.parse(recentSearch) : null) || [];
    }, []);
    const [items, setItems] = useState(initialItems);

    useEffect(() => {
        if (currentSearch === null || currentSearch.length < 3) {
            return;
        }

        function updateItems(newItems) {
            setItems(newItems);
            localStorage.setItem('recentSearch', JSON.stringify(newItems));
        }

        updateItems([
            ...items.filter(
                (it) =>
                    currentSearch.match(new RegExp(`^${it}`, 'gi')) === null,
            ),
            currentSearch,
        ]);
    }, [currentSearch]);

    return items;
}

export default useRecentSearch;
