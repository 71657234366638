/* eslint-disable react/jsx-indent */

/* eslint-disable react/jsx-props-no-spreading */
import { useIntersectionObserver, useResizeObserver } from '@folklore/hooks';
import { useVirtualizer } from '@tanstack/react-virtual';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import useTopicsInfinite from '../../hooks/useTopicsInfinite';

import TaxonomyHorizontalCard from '../cards/TaxonomyHorizontalCard';
import MenuSectionTitle from '../typography/MenuSection';
import ListPanel from './ListPanel';

import styles from '../../styles/panels/topics-panel.module.css';

const propTypes = {
    className: PropTypes.string,
    closePanel: PropTypes.func.isRequired,
};

const defaultProps = {
    className: null,
};

function TopicsPanel({ className, closePanel, ...props }) {
    const [search, setSearch] = useState(null);
    const [direction, setDirection] = useState(null);

    const {
        pages = null,
        isFetching,
        isFetchingNextPage,
        fetchNextPage,
    } = useTopicsInfinite({
        order: 'title',
        ...(search !== null
            ? {
                  search,
              }
            : null),
    });

    const onSearchChange = useCallback(
        (newSearch) => {
            setSearch(newSearch);
            setDirection('backward');
        },
        [setSearch],
    );

    const onClickBack = useCallback(() => {
        closePanel();
    }, [closePanel]);

    const {
        ref: refEnd,
        entry: { isIntersecting: endInView = false },
    } = useIntersectionObserver({
        disabled: isFetching || isFetchingNextPage,
    });

    useEffect(() => {
        if (endInView && !isFetching && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [isFetching, isFetchingNextPage, endInView]);

    // const withLetters = search === null;

    const items = useMemo(() => {
        if (pages === null) {
            return null;
        }
        return pages.reduce((allItems, { data: pageItems }) => [...allItems, ...pageItems], []);
    }, [pages]);

    // The scrollable element for your list
    const scrollRef = useRef();
    const {
        ref: headerRef,
        entry: { contentRect: headerContentRect = null },
    } = useResizeObserver();
    const { height: headerHeight = 0 } = headerContentRect || {};

    const onChanged = useCallback(
        (dt) => {
            if (dt.scrollDirection !== null) {
                setDirection(dt.scrollDirection);
            }
        },
        [setDirection],
    );

    // The virtualizer
    const rowVirtualizer = useVirtualizer({
        onChange: (dt) => {
            onChanged(dt);
        },
        count: items != null ? items.length : 0,
        getScrollElement: () => scrollRef.current,
        estimateSize: (index) => 40,
        paddingStart: headerHeight,
    });

    return (
        <ListPanel
            {...props}
            title={
                <MenuSectionTitle>
                    <FormattedMessage
                        defaultMessage="Sujets"
                        description="Menu section heading"
                    />
                </MenuSectionTitle>
            }
            onClickBack={onClickBack}
            onSearchChange={onSearchChange}
            search={search}
            loading={isFetching}
            ref={scrollRef}
            searchOnly={direction === 'forward'}
            headerRef={headerRef}
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            headerClassName={styles.header}
        >
            <div
                className={styles.items}
                style={{
                    height: rowVirtualizer.getTotalSize() - headerHeight,
                }}
            >
                {rowVirtualizer.getVirtualItems().map((virtualItem) => {
                    const it = items[virtualItem.index];
                    return (
                        <div
                            key={virtualItem.key}
                            className={styles.item}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: `${virtualItem.size}px`,
                                transform: `translateY(${virtualItem.start}px)`,
                            }}
                        >
                            <TaxonomyHorizontalCard
                                {...it}
                                withBorder
                                theme="without_thumbnail"
                                className={styles.card}
                                key={`category-${it.id}`}
                            />
                        </div>
                    );
                })}
            </div>
            <div
                className={styles.end}
                ref={refEnd}
                style={{
                    height: 40,
                }}
            />
        </ListPanel>
    );
}

TopicsPanel.propTypes = propTypes;
TopicsPanel.defaultProps = defaultProps;

export default TopicsPanel;
