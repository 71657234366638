/* eslint-disable react/jsx-indent */

/* eslint-disable react/jsx-props-no-spreading */
import { useIntersectionObserver, useResizeObserver } from '@folklore/hooks';
import { useVirtualizer } from '@tanstack/react-virtual';
import classNames from 'classnames';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import useAuthorsInfinite from '../../hooks/useAuthorsInfinite';
import usePlaceholderItems from '../../hooks/usePlaceholderItems';

import BasicButton from '../buttons/BasicButton';
import AuthorHorizontalCard from '../cards/AuthorHorizontalCard';
import MenuSectionTitle from '../typography/MenuSection';
import MenuSubSectionTitle from '../typography/MenuSubSection';
import ListPanel from './ListPanel';

import styles from '../../styles/panels/authors-panel.module.css';

const propTypes = {
    className: PropTypes.string,
    closePanel: PropTypes.func.isRequired,
};

const defaultProps = {
    className: null,
};

function AuthorsPanel({ className, closePanel, ...props }) {
    const [search, setSearch] = useState(null);
    const [direction, setDirection] = useState(null);

    const letters = 'abcdefghijklmnopqrstuvwxyz'.split('');

    const {
        pages = null,
        isFetching,
        isFetchingNextPage,
        fetchNextPage,
    } = useAuthorsInfinite(
        search !== null
            ? {
                  search,
              }
            : {
                  order: 'name',
              },
    );

    const [firstPage = null] = pages || [];
    const { pagination = null, letters: lettersCount = null } = firstPage || {};
    const { per_page: perPage = 20, total: totalCount = 0 } = pagination || {};

    const onSearchChange = useCallback(
        (newSearch) => {
            setSearch(newSearch);
            setDirection('backward');
        },
        [setSearch],
    );

    const onClickBack = useCallback(() => {
        closePanel();
    }, [closePanel]);

    const withLetters = search === null;
    const placeholderItems = usePlaceholderItems(20);

    const items = useMemo(() => {
        if (pages === null) {
            return ['A', ...placeholderItems];
        }
        if (!withLetters) {
            return [...Array(totalCount).keys()].map((itemIndex) => {
                const currentPageNumber = Math.floor(itemIndex / perPage) + 1;
                const pageStartIndex = (currentPageNumber - 1) * perPage;
                const itemPageIndex = itemIndex - pageStartIndex;
                const currentPage =
                    pages.find(
                        ({ pagination: { page: pageNumber } }) => pageNumber === currentPageNumber,
                    ) || null;
                const { data: currentPageItems = [] } = currentPage || {};
                return currentPageItems[itemPageIndex] || null;
            });
        }

        const itemsWithLetters = letters.reduce((allItems, letter) => {
            const count = lettersCount[letter];
            const currentItemsCount = allItems.filter((it) => it === null || !isString(it)).length;
            return [
                ...allItems,
                letter.toUpperCase(),
                ...[...Array(count).keys()].map((index) => {
                    const itemIndex = currentItemsCount + index;
                    const currentPageNumber = Math.floor(itemIndex / perPage) + 1;
                    const pageStartIndex = (currentPageNumber - 1) * perPage;
                    const itemPageIndex = itemIndex - pageStartIndex;
                    const currentPage =
                        pages.find(
                            ({ pagination: { page: pageNumber } }) =>
                                pageNumber === currentPageNumber,
                        ) || null;
                    const { data: currentPageItems = [] } = currentPage || {};
                    return currentPageItems[itemPageIndex] || null;
                }),
            ];
        }, []);

        return itemsWithLetters;

        // const { items: itemsWithLetters = [] } = pages.reduce(
        //     (acc, { data: pageItems }) =>
        //         pageItems.reduce(({ letter: currentLetter = null, items: currentItems }, it) => {
        //             const { name } = it;
        //             const firstLetter = name
        //                 .substr(0, 1)
        //                 .normalize('NFD')
        //                 .replace(/[\u0300-\u036f]/g, '')
        //                 .toUpperCase();
        //             return currentLetter !== firstLetter
        //                 ? {
        //                       letter: firstLetter,
        //                       items: [...currentItems, firstLetter, it],
        //                   }
        //                 : {
        //                       letter: currentLetter,
        //                       items: [...currentItems, it],
        //                   };
        //         }, acc),
        //     {
        //         letter: null,
        //         items: [],
        //     },
        // );

        // return itemsWithLetters;
    }, [placeholderItems, pages, withLetters, letters, lettersCount, perPage, totalCount]);

    // The scrollable element for your list
    const scrollRef = useRef();
    const {
        ref: headerRef,
        entry: { contentRect: headerContentRect = null },
    } = useResizeObserver();
    const { height: headerHeight = 0 } = headerContentRect || {};

    const onChanged = useCallback(
        (dt) => {
            if (dt.scrollDirection !== null) {
                setDirection(dt.scrollDirection);
            }
        },
        [setDirection],
    );

    // The virtualizer
    const rowVirtualizer = useVirtualizer({
        onChange: (dt) => {
            onChanged(dt);
        },
        count: items != null ? items.length : 0,
        getScrollElement: () => scrollRef.current,
        estimateSize: (index) => {
            const item = items[index];
            return isString(item) ? 40 : 75;
        },
        paddingStart: 0,
        // paddingStart: headerHeight,
    });

    const { startIndex = 0, endIndex = 0 } = rowVirtualizer.range || {};
    const pagesToLoad = useMemo(
        () =>
            pages !== null
                ? [...Array(endIndex - startIndex).keys()].reduce((currentPages, index) => {
                      const currentIndex = startIndex + index;
                      const lettersItems = items
                          .slice(0, currentIndex)
                          .filter((it) => it !== null && isString(it));
                      const currentItemIndex = currentIndex - lettersItems.length;
                      const currentPageNumber = Math.floor(currentItemIndex / perPage) + 1;
                      const currentPage =
                          pages.find(
                              ({ pagination: { page: pageNumber } }) =>
                                  pageNumber === currentPageNumber,
                          ) || null;
                      return currentPages.indexOf(currentPageNumber) === -1 && currentPage === null
                          ? [...currentPages, currentPageNumber]
                          : currentPages;
                  }, [])
                : [],

        [startIndex, endIndex, perPage, pages, items],
    );

    useEffect(() => {
        if (pagesToLoad.length > 0 && !isFetching && !isFetchingNextPage) {
            const [pageToLoad] = pagesToLoad;
            fetchNextPage({
                pageParam: pageToLoad,
            });
        }
    }, [pagesToLoad, isFetching, isFetchingNextPage]);

    return (
        <ListPanel
            {...props}
            title={
                <MenuSectionTitle>
                    <FormattedMessage
                        defaultMessage="Collaborateur·rice·x·s"
                        description="Menu section heading"
                    />
                </MenuSectionTitle>
            }
            onClickBack={onClickBack}
            onSearchChange={onSearchChange}
            search={search}
            loading={isFetching}
            ref={scrollRef}
            searchOnly={direction === 'forward'}
            header={
                <div className={styles.alphabet}>
                    {letters.map((letter) => (
                        <BasicButton
                            className={styles.alphabetLetter}
                            onClick={() => {
                                const letterIndex = items.indexOf(letter.toUpperCase());
                                rowVirtualizer.scrollToIndex(letterIndex, {
                                    align: 'start',
                                    // toOffset: 40,
                                });
                            }}
                        >
                            {letter.toUpperCase()}
                        </BasicButton>
                    ))}
                </div>
            }
            headerRef={headerRef}
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <div
                className={styles.items}
                style={{
                    height: rowVirtualizer.getTotalSize() - headerHeight,
                }}
            >
                {rowVirtualizer.getVirtualItems().map((virtualItem) => {
                    const it = items[virtualItem.index];
                    return (
                        <div
                            key={virtualItem.key}
                            className={styles.item}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: `${virtualItem.size}px`,
                                transform: `translateY(${virtualItem.start}px)`,
                            }}
                        >
                            {isString(it) ? (
                                <MenuSubSectionTitle className={styles.letter} key={`letter-${it}`}>
                                    {it}
                                </MenuSubSectionTitle>
                            ) : (
                                <AuthorHorizontalCard
                                    {...it}
                                    placeholder={it === null}
                                    theme="menu"
                                    className={styles.card}
                                />
                            )}
                        </div>
                    );
                })}
            </div>
        </ListPanel>
    );
}

AuthorsPanel.defaultProps = defaultProps;
AuthorsPanel.propTypes = propTypes;

export default AuthorsPanel;
