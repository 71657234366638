/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable no-unused-vars */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { component } from '../../lib/PropTypes';

import { useDefaultBrand } from '../../contexts/SiteContext';

import { ReactComponent as Dehors } from '../../assets/img/brands/dehors_menu.svg';
import { ReactComponent as Mollo } from '../../assets/img/brands/mollo_menu.svg';
import { ReactComponent as Quatre95 } from '../../assets/img/brands/quatre95_menu.svg';
import { ReactComponent as PeriodeLibre } from '../../assets/img/brands/periodelibre_menu.svg';
import urbania from '../../assets/img/brands/urbania_menu.png';
import france from '../../assets/img/brands/france_menu.png';

const logos = [
    {
        id: 'urbania',
        src: urbania,
    },
    {
        id: 'france',
        src: france,
    },
    {
        id: 'dehors',
        component: Dehors,
    },
    {
        id: 'quatre95',
        component: Quatre95,
    },
    {
        id: 'mollo',
        component: Mollo,
    },
    {
        id: 'periodelibre',
        component: PeriodeLibre,
    },
];

const propTypes = {
    brand: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    brand: null,
    className: null,
};

function BrandLogoMenu({ brand, className }) {
    const { handle: defaultBrandHandle = 'urbania', label: defaultBrandLabel = 'Urbania' } =
        useDefaultBrand();

    const { handle: brandHandle = defaultBrandHandle, label = defaultBrandLabel } = brand || {};

    const logo = logos.find(({ id }) => id === brandHandle) || null;

    const { src = null, component: Component = null } = logo || {};

    return Component !== null ? (
        <Component
            fill="currentColor"
            className={className}
            alt={label || 'Logo'}
            title={label || 'Logo'}
        />
    ) : (
        <img src={src} className={className} alt={label || 'Logo'} title={label || 'Logo'} />
    );
}

BrandLogoMenu.propTypes = propTypes;
BrandLogoMenu.defaultProps = defaultProps;

export default BrandLogoMenu;
