/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import PillButton from '../buttons/PillButton';

import styles from '../../styles/partials/search-filters.module.css';

const propTypes = {
    filters: PropTypes.node,
    className: PropTypes.string,
    onClickFilter: PropTypes.func,
};

const defaultProps = {
    filters: null,
    className: null,
    onClickFilter: null,
};

function SearchFilters({ filters, className, onClickFilter }) {
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            {filters.map(({ current = false, ...filter }) => (
                <PillButton
                    key={`filter-${filter.id}`}
                    className={classNames([
                        styles.button,
                        {
                            [styles.selected]: current,
                        },
                    ])}
                    compact
                    onClick={(e) => {
                        if (onClickFilter !== null) {
                            onClickFilter(e, filter);
                        }
                    }}
                >
                    {filter.label}
                </PillButton>
            ))}
        </div>
    );
}

SearchFilters.propTypes = propTypes;
SearchFilters.defaultProps = defaultProps;

export default SearchFilters;
