/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUrlGenerator } from '@folklore/routes';
import { useTracking } from '@folklore/tracking';
import { getComponentFromName } from '@folklore/utils';
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { faPinterest } from '@fortawesome/free-brands-svg-icons/faPinterest';
import { faSnapchat } from '@fortawesome/free-brands-svg-icons/faSnapchat';
import { faTiktok } from '@fortawesome/free-brands-svg-icons/faTiktok';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'wouter';

import * as AppPropTypes from '../../lib/PropTypes';

import { useSite } from '../../contexts/SiteContext';
// import * as BrandsSponsors from '../../assets/img/sponsors/menu';
import BasicButton from '../buttons/BasicButton';
import BrandLogoMenu from '../icons/BrandLogoMenu';
import LinksMenu from '../menus/LinksMenu';
import PillsMenu from '../menus/PillsMenu';
import Tagline from '../typography/Tagline';

import styles from '../../styles/headers/menu-header.module.css';

import urbaniaLogo from '../../assets/img/brands/urbania_logo.svg';
// import { ReactComponent as Dehors } from '../../assets/img/sponsors/dehors_menu_sponsor.svg';
import quatre95Sponsor from '../../assets/img/sponsors/quatre95_menu_sponsor.svg';

const socialIcons = {
    facebook: faFacebook,
    twitter: faTwitter,
    tiktok: faTiktok,
    linkedin: faLinkedin,
    instagram: faInstagram,
    youtube: faYoutube,
    pinterest: faPinterest,
    snapchat: faSnapchat,
};

const BrandsSponsors = {
    // Dehors,
};

const propTypes = {
    brand: AppPropTypes.brand,
    className: PropTypes.string,
};

const defaultProps = {
    brand: null,
    className: null,
};

function MenuHeader({ brand, className }) {
    const url = useUrlGenerator();
    // const { section: sectionParam = null } = useParams();

    const { subscriptions } = useSite();

    const {
        handle: brandHandle = null,
        slug: brandSlug = null,
        default: isDefault = false,
        tagline = null,
        sections = null,
        socials = null,
    } = brand || {};
    const subscription = (subscriptions || []).find(({ handle }) => handle === brandHandle) || null;
    // const brandHandle = 'france';

    const SponsorComponent = getComponentFromName(BrandsSponsors, brandHandle);
    const hasSponsor = SponsorComponent !== null;

    const tracking = useTracking();
    const onClickLogo = useCallback(() => {
        tracking.trackEvent('Navigation', 'click_menu_brand_logo', brandHandle || 'urbania');
    }, [tracking, brandHandle]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles[brandHandle]]: brandHandle !== null,
                    [styles.hasSponsor]: hasSponsor,
                },
                className,
            ])}
        >
            <div className={styles.top}>
                {brandHandle !== 'urbania' && brandHandle !== 'france' ? (
                    <BasicButton href="/" className={styles.topButton} aria-label="Urbania">
                        <img
                            src={brandHandle === 'quatre95' ? quatre95Sponsor : urbaniaLogo}
                            className={styles.topLogoUrbania}
                            alt="Urbania"
                        />
                    </BasicButton>
                ) : null}
            </div>
            <div className={styles.logoContainer}>
                <Link
                    href={
                        brandSlug !== null
                            ? url('home', {
                                  brand: brandSlug,
                              })
                            : '/'
                    }
                    className={styles.logoLink}
                    onClick={onClickLogo}
                >
                    <BrandLogoMenu brand={brand} className={styles.logo} />
                </Link>
            </div>
            <div className={styles.brandingWrapper}>
                <div className={styles.branding}>
                    {SponsorComponent !== null ? (
                        <div className={styles.sponsorContainer}>
                            <SponsorComponent fill="currentColor" className={styles.sponsor} />
                        </div>
                    ) : null}
                    {tagline !== null ? (
                        <Tagline className={styles.tagline}>{tagline}</Tagline>
                    ) : null}
                </div>
            </div>
            <PillsMenu
                items={(sections || []).map(({ id, label, slug }) => ({
                    id,
                    label,
                    href: url('section', {
                        brand: brandSlug,
                        section: slug,
                    }),
                }))}
                className={styles.sectionsMenu}
                itemsClassName={styles.sectionItems}
                buttonClassName={styles.sectionPill}
            />
            <PillsMenu
                items={[
                    {
                        id: 'videos',
                        label: <FormattedMessage defaultMessage="Vidéos" description="Menu item" />,
                        href: url('videos', {
                            brand: brandSlug,
                        }),
                    },
                    {
                        id: 'articles',
                        label: (
                            <FormattedMessage defaultMessage="Articles" description="Menu item" />
                        ),
                        href: url('articles', {
                            brand: brandSlug,
                        }),
                    },
                   brandHandle === 'dehors'
                        ? {
                              id: 'map',
                              label: (
                                  <FormattedMessage
                                      defaultMessage="Carte"
                                      description="Menu item"
                                  />
                              ),
                              href: url('map'),
                          }
                        : null,
                    isDefault || brandHandle === 'quatre95'
                        ? {
                              id: 'podcasts',
                              label: (
                                  <FormattedMessage
                                      defaultMessage="Balados"
                                      description="Menu item"
                                  />
                              ),
                              href: url('podcasts', {
                                  brand: brandSlug,
                              }),
                          }
                        : null,
                    isDefault
                        ? {
                              id: 'micromags',
                              label: (
                                  <FormattedMessage
                                      defaultMessage="Micromags"
                                      description="Menu item"
                                  />
                              ),
                              href: url('micromags', {
                                  brand: brandSlug,
                              }),
                          }
                        : null,
                ].filter((it) => it !== null)}
                className={styles.indexMenu}
                itemsClassName={styles.indexItems}
                buttonClassName={styles.indexPill}
            />
            {socials !== null || subscription !== null ? (
                <LinksMenu
                    items={[
                        // ...(subscription !== null
                        //     ? [
                        //           {
                        //               id: 'subscription',
                        //               label: (
                        //                   <FormattedMessage
                        //                       defaultMessage="S'abonner"
                        //                       description="Menu item"
                        //                   />
                        //               ),
                        //               href: url('subscribe', {
                        //                   brand: brandSlug,
                        //               }),
                        //               className: styles.subscribe,
                        //           },
                        //       ]
                        //     : []),
                        ...(socials || []).map(({ id, label, url: socialUrl }) => ({
                            id: id || label,
                            label:
                                typeof socialIcons[id] !== 'undefined' ? (
                                    <FontAwesomeIcon icon={socialIcons[id]} />
                                ) : (
                                    label
                                ),
                            href: socialUrl,
                            external: true,
                        })),
                    ]}
                    className={styles.linksMenu}
                    itemClassName={styles.linkItem}
                    linkClassName={styles.linkLink}
                />
            ) : null}
        </div>
    );
}

MenuHeader.propTypes = propTypes;
MenuHeader.defaultProps = defaultProps;

export default MenuHeader;
