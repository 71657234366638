/* eslint-disable react/jsx-props-no-spreading */
import { useResizeObserver } from '@folklore/hooks';
import { useSpring, animated } from '@react-spring/web';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';

import BasicButton from '../buttons/BasicButton';
import SearchField from '../fields/SearchField';
import ArrowIcon from '../icons/ArrowIcon';

import styles from '../../styles/panels/list-panel.module.css';

const propTypes = {
    title: PropTypes.node,
    searchOnly: PropTypes.bool,
    search: PropTypes.func,
    header: PropTypes.node,
    loading: PropTypes.bool,
    containerRef: AppPropTypes.ref,
    headerRef: AppPropTypes.ref,
    onSearchChange: PropTypes.func,
    onClickBack: PropTypes.func,
    children: PropTypes.node,
    className: PropTypes.string,
    headerClassName: PropTypes.string,
};

const defaultProps = {
    title: null,
    onSearchChange: null,
    onClickBack: null,
    searchOnly: false,
    search: null,
    loading: false,
    header: null,
    containerRef: null,
    headerRef: null,
    children: null,
    className: null,
    headerClassName: null,
};

function ListPanel({
    title,
    onClickBack,
    onSearchChange,
    searchOnly,
    search,
    loading,
    header,
    containerRef,
    headerRef,
    children,
    className,
    headerClassName,
}) {
    const intl = useIntl();

    const [toolbarOpened, setToolbarOpened] = useState(!searchOnly);
    const {
        ref: toolbarRef,
        entry: { contentRect: toolbarContentRect = null },
    } = useResizeObserver({
        disabled: !toolbarOpened,
    });

    const { height: toolbarHeight = null } = toolbarContentRect || {};

    const style = useSpring({
        height: searchOnly ? 0 : 60,
        config: {
            duration: 250,
        },
        onStart: () => {
            setToolbarOpened(false);
        },
        onRest: () => {
            if (!searchOnly) {
                setTimeout(() => {
                    setToolbarOpened(true);
                }, 250); // 200ms de delay
            }
        },
    });

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            ref={containerRef}
        >
            <div className={classNames([styles.header, headerClassName])} ref={headerRef}>
                <animated.div
                    className={styles.toolbar}
                    style={style}
                    ref={!searchOnly ? toolbarRef : null}
                >
                    <BasicButton className={styles.backButton} onClick={onClickBack}>
                        <ArrowIcon className={styles.icon} />
                    </BasicButton>
                    <div className={styles.title}>{title}</div>
                </animated.div>
                <SearchField
                    value={search}
                    placeholder={intl.formatMessage({
                        defaultMessage: 'Recherche...',
                        description: 'Field placeholder',
                    })}
                    loading={loading}
                    onChange={onSearchChange}
                    className={styles.field}
                />
                {header}
            </div>
            <div className={styles.items}>{children}</div>
        </div>
    );
}

ListPanel.propTypes = propTypes;
ListPanel.defaultProps = defaultProps;

export default React.forwardRef((props, ref) => <ListPanel {...props} containerRef={ref} />);
