import { useRoutes, useRouteMatcher } from '@folklore/routes';
import { useLocation } from 'wouter';

export function useMenuPanels() {
    const routes = useRoutes();
    return {
        menu: routes.menu,
        search: routes.search,
        authors: routes.authors,
        categories: routes.categories,
        topics: routes.topics,
        // micromags: routes.micromags,
        // articles: routes.articles,
        // videos: routes.videos,
        // podcasts: routes.podcasts,
        collections: routes.collections,
    };
}

function useMenuPanel(menuLocation = null) {
    const [location] = useLocation();
    const routeMatcher = useRouteMatcher();
    const panels = useMenuPanels();
    return (
        Object.keys(panels).find((key) => {
            const [matches = null] = routeMatcher(
                panels[key],
                (menuLocation || location).split('?')[0],
            );
            return matches;
        }) || null
    );
}

export default useMenuPanel;
